'use client';

import React from 'react';
import { onValue, ref, off } from 'firebase/database';
import { authFirebase, database } from '@/lib/firebase';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';

interface FirebaseContextType {
  isOnline: (id: number) => boolean;
  loading: boolean;
}

const FirebaseContext = React.createContext<FirebaseContextType | undefined>(undefined);

export const FirebaseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [data, setData] = React.useState<Record<number, boolean> | null>(null);
  const [loading, setLoading] = React.useState(true);

  const contextValue = React.useMemo(
    () => ({
      isOnline: (id: number) => Boolean(data?.[id]),
      loading,
    }),
    [data, loading]
  );

  React.useEffect(() => {
    let unsubscribeAuth: (() => void) | undefined;
    let unsubscribeDB: (() => void) | undefined;

    const setupFirebase = async () => {
      unsubscribeAuth = onAuthStateChanged(authFirebase, (user) => {
        if (!user) {
          signInAnonymously(authFirebase).catch((error) => {
            console.error('Authentication error:', error);
          });
        }
      });

      const dbRef = ref(database, '/online');
      unsubscribeDB = onValue(dbRef, (snapshot) => {
        if (snapshot.exists()) {
          setData(snapshot.val());
        }
        setLoading(false);
      });
    };

    setupFirebase();

    return () => {
      if (unsubscribeAuth) unsubscribeAuth();
      if (unsubscribeDB) {
        unsubscribeDB();
        off(ref(database, '/online'));
      }
    };
  }, []);

  return <FirebaseContext.Provider value={contextValue}>{children}</FirebaseContext.Provider>;
};

export const useFirebase = () => {
  const context = React.useContext(FirebaseContext);
  if (!context) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
};
